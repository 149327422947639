export const LANGUAGES = [
    { label: "English", code: "en" },
    { label: "Deutsch", code: "de" },
    { label: "Русский", code: "ru" },
    { label: "It", code: "it"},
    { label: "All", code: "all" },
];

export const AUDIO_FILES = [
    "https://static.roomab.art/mp3/ne-moozhhet-biiit-ne-smeeshshsite-mmemenniiiiaaff8559b0668329cfe29804d0e9ea0c0e.mp3"
]