import React from 'react'
import Autocomplete from '../Autocomplete';
import ReactHtmlParser from 'react-html-parser';
import {useLocation, useNavigate} from 'react-router-dom';
import {Translation} from "react-i18next";
import i18n from "../i18n";

function withHooks(WrappedComponent) {
    return function(props) {
        const location = useLocation();
        const navigate = useNavigate();
        return <WrappedComponent location={location} navigate={navigate} {...props}/>
    };
}

class Word extends React.Component {
    constructor(props) {
        super(props);

        this.state = { kart:
                {
                    id: 0,
                    word: '',
                    translate: '',
                    example: '',
                    translate1: '',
                    example1: '',
                    example_translate: '',
                    tran_ind: '',
                    multi_def: 0,
                    cat: ''
                },
            answers: '',
            input: JSON.parse(localStorage.getItem('input_ball')),
            erudite: localStorage.getItem('erudite') ? JSON.parse(localStorage.getItem('erudite')) : 0,
            open: 0,
            open1: 0,
            button_hint: 0,
            wordOpen: 1,
            inputOpen: 1,
            vorbei: [],
            repeat_value: 0,
            user: JSON.parse(localStorage.getItem('spr_name')) || props.location.state.name,
            lang: props.location.state.lang
        };

        i18n.changeLanguage(props.location.state.lang);

    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    getData = (val) =>{
        if (val === this.state.kart.word) {
            this.openTranslate()
            this.toggleButtonHint(0)
            this.setState(state =>({input: this.state.input+1, inputOpen: 0}))
            localStorage.setItem('input_ball', JSON.stringify(this.state.input+1));

            this.sleep(10000).then(r => {
                if(this.state.input>9 || this.state.erudite>9){
                    this.endMatch();
                }
                this.openInput()
                this.nextWord()
                this.addVorbyId(this.state.kart.id)
            })
        }
    }

    addVorbyId = (id) => {
        this.setState(state => ({
            vorbei: [...state.vorbei, id]
        }))
    }


    nextWord = () => {
        this.getWord()
    };

    endMatch = () => {
        this.setState(state => ({
            wordOpen: 0
        }))
        localStorage.setItem('input_ball', JSON.stringify(0));
        localStorage.setItem('erudite', JSON.stringify(0));
    }

    newWord = () => {
        this.getWord(this.state.kart.id+1)
    };

    logOut = () => {
        this.props.navigate('/');
    }

    openTranslate = () => {
        this.setState(state => ({
            open: 1,
            inputOpen: 0
        }))
    }

    openInput = () => {
        this.setState({ inputOpen: 1 })
    }

    showWord = () => {
        this.openTranslate();
        this.toggleButtonHint(0);
        this.sleep(10000).then(r => {
            if(this.state.input>9 || this.state.erudite>9){
                this.endMatch();
            }
            else {
                this.getWord(this.state.kart.id+1)
                this.setState({erudite: this.state.erudite+1})
                localStorage.setItem('erudite', JSON.stringify(this.state.erudite+1));
            }
        });
    }

    showDefinition = () => {
        this.setState(state => ({
            open1: 1
        }))
    }

    toggleButtonHint = (open) => {
        if(open){
            this.setState(state => ({
                button_hint: 1
            }))
        } else {
            this.setState(state => ({
                button_hint: 0
            }))
        }

    }

    getWord = () => {
        /*
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch('http://0.0.0.0:3001/words/'+ id, requestOptions)
         - da kan man drin-server anschliesen*/
        //fetch('http://88.85.67.159:8094/word/' + id)
        fetch('https://api.roomab.art/rand-word/'+this.state.lang)
            .then(response => response.json())
            .then(data => {
                if (this.state.repeat_value>30){
                    window.location.reload();
                }
                else if (data.status === false ||
                    this.state.vorbei.includes(data.id) ) {
                    this.setState(state => ({repeat_value: this.state.repeat_value+1}))
                    this.getWord()
                    this.openInput()
                }
                else {
                    let ind = Math.floor(Math.random()*data.definitions.length)
                    this.setState(state => ({
                        kart:
                            {
                                id: data.id,
                                word: data.word,
                                tran_ind: data.definitions.length,
                                translate: data.definitions[ind].value,
                                example: this.getHtmlFromExampleObjArr(data.definitions[ind].examples),
                                translate1: data.definitions[ind+1] !== undefined
                                    ? data.definitions[ind+1].value : (data.definitions[ind-1] !== undefined
                                        ? data.definitions[ind-1].value : ''),
                                example1: data.definitions[ind+1] !== undefined  ?
                                    this.getHtmlFromExampleObjArr(data.definitions[ind+1].examples) :
                                    (data.definitions[ind-1] !== undefined
                                        ? this.getHtmlFromExampleObjArr(data.definitions[ind-1].examples) : ''),
                                multi_def: data.definitions.length-1,
                                cat: data.lang
                            },
                        open: 0,
                        open1: 0,
                        button_hint: data.definitions.length-1,
                        repeat_value: this.state.repeat_value+1
                    }))
                    this.openInput()
                    this.toggleButtonHint(data.definitions.length-1)
                }
            })
    }

    getAnswers(json){
        let arr = []
        for(let i = 0; i < json.length; i++) {
            let obj = json[i];
            arr.push(obj);
        }
        //this.state.answers = arr;
        this.setState(state => ({answers: arr}))
        //this.setState(state => ({repeat_value: this.state.repeat_value+1}))
    }

    getHtmlFromExampleObjArr(arr){
        let string = ``
        for (let i = 0; i < arr.length; i++) {
            let ex = arr[i].text;
            string += ex + "<br>";
        }
        return string
    }

    getLand(langNum){
        switch (langNum){
            case '0': return 'eng';
            case '1': return 'de';
            case '2': return 'ru';
            case '3': return 'it';
            default: return '';
        }
    }

    parseHTML(html) {
        let t = document.createElement('template');
        t.innerHTML = html;
        return t.content;
    }

    componentDidMount() {

        //fetch('http://0.0.0.0:3001/words', { method: 'GET',  headers: { 'Content-Type': 'application/json' }})
        fetch('https://api.roomab.art/words')
            .then(response => response.json())
            .then(data => this.getAnswers(data));
        this.getWord()
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <header className="App-header d-grid gap-2">
                <div className={this.state.wordOpen ? 'hidden' : 'victory_block Word'}>
                    <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('victory')}</p>
                        }
                    </Translation>
                </div>
                <div className={this.state.wordOpen ? "Word" : 'hidden'}>
                    <span className='lanf'>{this.getLand(this.state.kart.cat)}</span>
                    <p className='definition'>{ReactHtmlParser(this.state.kart.translate)}
                    </p>

                    <p className={this.state.open ? 'em example' : 'hidden'}>{ReactHtmlParser(this.state.kart.example)}</p>
                    <p className={this.state.open1 ? 'definition' : 'hidden'}>
                        {((this.state.kart.translate1 !== undefined))
                            ? ReactHtmlParser(this.state.kart.translate1) : ''}
                    </p>
                    <p className={this.state.open ? 'em example' : 'hidden'}>
                        {((this.state.kart.example1 !== undefined))
                            ? ReactHtmlParser(this.state.kart.example1) : ''}
                    </p>
                    <p className={this.state.open ? 'word_res' : 'hidden'}>{this.state.kart.word}</p>
                </div>
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) =>
                            <p className="ball">{this.state.user} {this.state.input}:{this.state.erudite} Erudite</p>
                    }
                </Translation>

                <Autocomplete
                    suggestions={this.state.answers}
                    sendData={this.getData}
                    inputOpen={this.state.inputOpen}/>
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) =>
                            <button type="button" className="btn btn-secondary btn-lg btn-block" onClick={this.logOut}>
                                {t('logout')}
                            </button>
                    }
                </Translation>

                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) =>
                            <button type="button"
                                    className={this.state.open ? 'hidden' : 'btn btn-secondary btn-lg btn-block'}
                                    onClick={this.showWord}>
                                {t('do_not_know')}
                            </button>
                    }
                </Translation>

                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) =>
                            <button type="button"
                                    className={this.state.button_hint ? 'btn btn-secondary btn-lg btn-block' : 'hidden' }
                                    onClick={this.showDefinition}>
                                {t('hint')}
                            </button>
                    }
                </Translation>
            </header>
        );
    }
}

export default withHooks(Word)